interface LogoutState {
    isLogout: boolean;
}

const INITIAL_LOGOUT_STATE: LogoutState = {
   isLogout: false,
};

export type LogoutAction = {
    type: string;
    payload: boolean;
}

export enum LogoutActionType {
    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export function logoutReducer(state = INITIAL_LOGOUT_STATE, action: LogoutAction): LogoutState {
   switch (action.type) {
   case LogoutActionType.LOGOUT_ACTION: {
      return { ...state, isLogout: action.payload };
   }
   default:
      return state;
   }
}
