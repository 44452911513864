/* eslint-disable no-unused-expressions,no-restricted-globals */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-dialog";
import "@polymer/paper-input/paper-input";
import "@polymer/paper-button";
import "@vaadin/vaadin-icons";

import { is_esc, is_enter, have_to_move_to_right, have_to_move_to_left, clickEvent } from "../dialog/common-keyboard-handler";
import TechnicianUtils from "../utils/technician-utils";
import {CommonActionType, DialogActionType} from "../redux/reducers/common";
import store from "../redux/store";
import mixinCommons from "../common-mixin";
import RadiologyUtils from "../utils/radiology-utils";

/**
 * `healthhub-confirm-dialog`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class HhConfirmDialog extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
      <style>
         :host{
            /*position: fixed;*/
            top: 16px;
            right: 16px;
            /*width: 300px;*/
            /*height: 300px;*/
         }

         paper-dialog{
            width: 350px;
            background-color: #394451;
            z-index: 1;
         }

         paper-button{
            /*width: 122px;*/
            height: 35px;
            border: 0;
            font-size: 10pt;
            color: #ccc;
            cursor: pointer;
            border-radius: 2px;
            background: #4c5667;
         }

         .class-title{
            display: flex;
            margin-top: 20px;
         }

         .class-message{
               /*height: 25%;*/
         }

         .class-btn{
            text-align: center;
            margin: 20px 0 15px 0;
         }

         paper-button[focused]  {
            background: #0087cb;
         }

         .text-font{
            color: #ccc;
         }

         .loader {
            margin: 100px auto;
            font-size: 25px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            position: relative;
            text-indent: -9999em;
            -webkit-animation: load5 1.1s infinite ease;
            animation: load5 1.1s infinite ease;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
        }
        @-webkit-keyframes load5 {
            0%,
            100% {box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);}
            12.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);}
            25% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            37.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            50% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            62.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            75% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            87.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;}
        }
        @keyframes load5 {
            0%,
            100% {box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);}
            12.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);}
            25% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            37.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            50% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            62.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            75% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);}
            87.5% {box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;}
        }

         .check-box-style {
            --paper-checkbox-label-color: #aaaaaa;
            --paper-checkbox-checked-color: #2987ca;
            --paper-checkbox-unchecked-color: #aaaaaa;
         }

      </style>
      <paper-dialog id="confirmDialog" on-click="prevent_event_propagation" on-keydown="close_if_esc" on-keypress="prevent_event_propagation" on-keyup="prevent_event_propagation" on-focus="focus_on_selected_button" on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed" alwaysOnTop>
         <div class="class-title">
            <!--<h2 id="title" class="text-font" style="display: flex"><iron-icon icon="vaadin:warning" style="color: #aaaaaa">{{message.title}}</h2>-->
            <template is="dom-if" if="{{message.title}}">
               <iron-icon icon="vaadin:warning" style="color: #aaaaaa; width: 30px"></iron-icon>
               <div id="title" class="text-font" style="margin: 3px 0 0 10px; display: flex; font-size: 20px;">{{message.title}}</div>
            </template>
         </div>
         <div class="class-message">
            <template is="dom-repeat" items="{{message.contents}}" as="contents">
               <p class="text-font">{{contents}}</p>
            </template>
            <!--
                loader
                Dialog, title, content, button 항목을 모두 제거하고 modal: true, loading: true 값으로 준다.
            -->

            <template is="dom-if" if="{{message.checkbox}}">
               <paper-checkbox class="check-box-style" id="confirmDialogCheckbox" checked="{{message.checkbox.checked}}">{{checkboxLabel}}</paper-checkbox>
            </template>

            <template is="dom-if" if="{{message.loading}}">
                <div class="loader">Loading..</div>
            </template>
         </div>
         <div class="class-btn">
            <template is="dom-if" if="{{message.ok}}">
                <paper-button id="okBtn" on-click="doOk" dialog-confirm tabindex=0>{{message.ok}}</paper-button>
            </template>
            <template is="dom-if" if="{{message.discard}}">
                <paper-button id="discardBtn" on-Click="doDiscard" dialog-confirm tabindex=1>{{message.discard}}</paper-button>
            </template>
            <template is="dom-if" if="{{message.cancel}}">
                <paper-button id="cancelBtn" on-click="doClose" dialog-dismiss tabindex=2>{{message.cancel}}</paper-button>
            </template>
         </div>
      </paper-dialog>
      `;
   }

   static get is() {
      return "hh-confirm-dialog";
   }

   static get properties() {
      return {
         message: {
            type: Object
         },
         checkboxLabel: {
            type: String
         }
      };
   }

   ready() {
      super.ready();

      ["click", "keydown", "keypress", "keyup"].forEach(eventType => this.$.confirmDialog.addEventListener(eventType, e => e.stopPropagation() && false));

      const buttons = this.$.confirmDialog.getElementsByTagName("paper-button");
      this.toggle = () => [...buttons].forEach((button, index) => {
         const set = this.focused_index === index;
         Object.assign(button, {focused: set, active: set});
         if(set) button.focus();
      });

      this.$.confirmDialog.addEventListener("keyup", (e) => {
         // if(have_to_move_to_right(e) || have_to_move_to_left(e)) {
         //    this.focused_index = 1 - this.focused_index;
         if (have_to_move_to_right(e)) {
            if ((buttons.length - 1) === this.focused_index) {
               this.focused_index = 0;
            } else {
               this.focused_index += 1;
            }
            this.toggle();
         } else if (have_to_move_to_left(e)) {
            if (this.focused_index === 0) {
               this.focused_index = buttons.length - 1;
            } else {
               this.focused_index -= 1;
            }
            this.toggle();
         } else if(is_enter(e)) {
            [...buttons][this.focused_index].dispatchEvent(clickEvent);
         } else if(is_esc(e)) {
            [...buttons].filter( button => button.id === "cancelBtn" ).map(cancelBtn => cancelBtn.dispatchEvent(clickEvent));
         }
         e.stopPropagation();
      });
   }

   close_if_esc(e) {
      e.stopPropagation();
      if(is_esc(e)) {
         this.doClose();
      }
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   focus_on_selected_button() {
      this.toggle();
   }

   dialog_opened() {
      // if(window.report && window.report.blocking) window.report.blocking.open();
      if(window.dialog_opened) window.dialog_opened({dialog:this.$.confirmDialog, is_modal:this.$.confirmDialog.modal});
   }

   dialog_closed() {
      // if(window.report && window.report.blocking) window.report.blocking.close();
      if(window.dialog_closed) window.dialog_closed();
   }

   /**
    * Confirm Dialog 열기
    *
    * Create by BohyunJang on 2018-12-18 오후 4:26
    */
   doOpen(message) {
      const {filmbox} = window.document;
      if(window.report && typeof window.report.get === "function" && window.report.get()) window.report.get().open("", "worklist");
      if(filmbox) filmbox.open("", "worklist");

      self.focus();
      if (message.actionType !== undefined) {
         const initOption = this.getInitDialogOption(message.actionType, message.params);
         if (message.size) {
            const { width = undefined, height = undefined } = message.size;
            if (width && height) this.doCustomSize(width, height);
            else if (width) this.doCustomSizeOnlyWidth(width);
            else if (height) this.doCustomSizeOnlyHeight(height);
         }
         this.message = {...message, ...initOption};
      } else {
         this.message = message;
      }
      if (this.message.modal) this.$.confirmDialog.modal = true;
      if (this.message.checkbox) {
         this.checkboxLabel = this.message.checkbox.label;
      }

      this.$.confirmDialog.open();
      this.focused_index = 0;
      this.toggle(); // 기본값으로 첫번째 버튼에 focus를 줌
   }

   getInitDialogOption(actionType, params = {}) {
      const message = {};
      switch (actionType) {
      case DialogActionType.UN_VERIFY: {
         this.doCustomSize(185, 355);
         message.contents = [ this.t("msg.unverify.0"), this.t("msg.unverify.1") ];
         message.title = this.t("label.unverify");
         message.ok = this.t("button.yes");
         message.cancel = this.t("button.no");
         return message;
      }
      case DialogActionType.MATCH: {
         this.doCustomSizeOnlyWidth(425);
         message.contents = TechnicianUtils.makeMatchUnMatchMessage(params);
         message.title = this.t("label.match");
         message.ok = this.t("button.yes");
         message.cancel = this.t("button.no");
         return message;
      }
      case DialogActionType.UN_MATCH: {
         this.doCustomSizeOnlyWidth(425);
         message.contents = TechnicianUtils.makeMatchUnMatchMessage(params);
         message.title = this.t("label.unmatch");
         message.ok = this.t("button.yes");
         message.cancel = this.t("button.no");
         return message;
      }
      case DialogActionType.DELETE_READING_TEMPLATE: {
         this.doCustomSizeOnlyWidth(400);
         message.contents = RadiologyUtils.makeDeleteReadingTemplateMessage(params);
         message.title = this.t("label.delete");
         message.ok = this.t("button.ok");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.DELETE_STUDY: {
         this.doCustomSize(180, 345);
         message.contents = [ "Deleted studies cannot be recovered. Do you want to proceed?" ];
         message.title = this.t("label.delete");
         message.ok = this.t("button.yes");
         message.cancel = this.t("button.no");
         return message;
      }
      case DialogActionType.CANCEL_MERGE: {
         this.doCustomSize(200, 320);
         message.contents = ["Merged studies will be canceled.", "Do you want to proceed?"];
         message.title = this.t("label.cancelMerge");
         message.ok = this.t("button.yes");
         message.cancel = this.t("button.no");
         return message;
      }
      case DialogActionType.REPORT_ALREADY_REQUEST: {
         if(params.state === "approve") {
            this.doCustomSize(167,500);
            message.title = this.t("label.confirmOpinion.alreadyReq.approve");
         } else if(params.state === "save") {
            this.doCustomSize(167,580);
            message.title = this.t("label.confirmOpinion.alreadyReq.save");
         } else if(params.state === "transcribe") {
            this.doCustomSize(167,580);
            message.title = this.t("label.confirmOpinion.alreadyReq.transcribe");
         }

         message.contents = [ this.t("msg.opinion.confirm.alreadyReq") ];
         message.ok = this.t("button.accept");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.REPORT_NOT_VERIFY: {
         if(params.state === "approve") {
            this.doCustomSizeOnlyWidth(500);
            message.contents = this.t("msg.opinion.confirm.notVerify.approve", { returnObjects: true });
            message.title = this.t("label.confirmOpinion.notVerify.approve");
         } else if (params.state === "save") {
            this.doCustomSizeOnlyWidth(500);
            message.contents = this.t("msg.opinion.confirm.notVerify.save", { returnObjects: true });
            message.title = this.t("label.confirmOpinion.notVerify.save");
         } else if (params.state === "transcribe") {
            this.doCustomSize(225,500);
            message.contents = this.t("msg.opinion.confirm.notVerify.transcribe", { returnObjects: true });
            message.title = this.t("label.confirmOpinion.notVerify.transcribe");
         }

         message.ok = this.t("button.accept");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.REPORT_SAVE_OPINION: {
         this.doCustomSize(225,485);
         message.contents = params.contents;
         message.title = this.t("label.saveOpinion");
         message.ok = this.t("button.accept");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.REPORT_TRANSCRIBE_OPINION: {
         this.doCustomSize(190,485);
         message.contents = params.contents;
         message.title = this.t("label.transcribeOpinion");
         message.ok = this.t("button.accept");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.MISMATCH: {
         this.doCustomSize(240,560);
         message.contents = this.t("msg.mismatchCase", { returnObjects: true });
         message.title = this.t("label.mismatchCase");
         message.ok = this.t("button.continueToRead");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.SIGN_CANCEL: {
         this.doCustomSize(230, 320);
         if (params.type === "ADDENDUM") {
            message.contents = [this.t("msg.digitalSign.dialog.addendumLabel.0"), this.t("msg.digitalSign.dialog.addendumLabel.1")];
            message.title = this.t("label.addendum");
            message.ok = this.t("button.addendum");
         } else {
            message.contents = [this.t("msg.digitalSign.dialog.approveLabel.0"), this.t("msg.digitalSign.dialog.approveLabel.1")];
            message.title = this.t("label.approve");
            message.ok = this.t("button.approve");
         }
         message.cancel = this.t("button.cancel");
         message.checkbox = {
            label: this.t("msg.digitalSign.dialog.checkboxLabel"),
            checked: false
         };
         return message;
      }
      case DialogActionType.PREFETCH_CLEAR: {
         this.doCustomSizeOnlyWidth(420);
         message.contents = this.t("msg.prefetch.confirmClean", { returnObjects: true } );
         message.title = this.t("button.prefetch.clean");
         message.ok = this.t("button.accept");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.CHANGE_CLINICAL_INFO: {
         this.doCustomSize(200, 400);
         message.title = this.t("label.clinicalInfo");
         message.contents = this.t("msg.clinicalInfo.confirm", { returnObjects: true });
         message.ok = this.t("button.ok");
         message.cancel = this.t("button.cancel");
         return message;
      }
      case DialogActionType.LOGOUT: {
         this.doCustomSize(200,375);
         message.title = this.t("label.logout");
         message.contents = this.t("msg.confirmLogout", { returnObjects: true });
         message.modal = true;
         message.ok = this.t("button.logout");
         message.cancel = this.t("button.cancel");
         return message;
      }
      default: return message;
      }
   }

   /**
    * Confirm Dialog Open Event
    *
    * Create by BohyunJang on 2018-12-18 오후 5:35
    */
   doOk() {
      const param = {};
      param.detail = this.message;
      this.dispatchEvent(new CustomEvent("confirm", param));

      const { action = () => Promise.resolve(), callback = () => {}, params, checkbox } = this.message;
      if (checkbox) params.checkbox = checkbox; // checkbox 데이터가 있으면 params 에 담아 return
      action(params).then(result => callback(result, params));
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   doDiscard() {
      const param = {};
      param.detail = this.message;
      this.dispatchEvent(new CustomEvent("confirmDiscard", param));

      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   /**
    * Confirm Dialog 닫기
    *
    * Create by BohyunJang on 2018-12-18 오후 3:39
    */
   doClose() {
      this.dispatchEvent(new CustomEvent("confirmCancel", {detail: this.message}));
      this.$.confirmDialog.close();

      if (this.message.cancelCallback) this.message.cancelCallback({ detail: this.message });
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   customClose() {
      this.$.confirmDialog.close();
   }

   /**
    * 사이즈 지정
    * @param height
    * @param width
    */
   doCustomSize(height, width) {
      this.$.confirmDialog.style.height = `${height}px`;
      this.$.confirmDialog.style.width = `${width}px`;
   }

   /**
    * Height는 자동으로 맞추고 Width만 조정
    * @param width
    */
   doCustomSizeOnlyWidth(width) {
      this.$.confirmDialog.style.width = `${width}px`;
   }

   /**
    * Width는 자동으로 맞추고 Height만 조정
    * @param height
    */
   doCustomSizeOnlyHeight(height) {
      this.$.confirmDialog.style.height = `${height}px`;
   }
}

window.customElements.define(HhConfirmDialog.is, HhConfirmDialog);
