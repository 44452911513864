export const is_composited_key = event => ["shift","alt","ctrl","meta"].some(mod=> event[`${mod}Key`]);
export const has_shift_only = event => event.shiftKey && ["alt","ctrl","meta"].every(mod=> !event[`${mod}Key`]);
export const have_to_move_to_right = event => (!is_composited_key(event) && (event.which === /* Right Arrow */39 || event.which=== /* TAB */9));
export const have_to_move_to_left = event => (!is_composited_key(event) && event.which === /* Left Arrow */37) || (has_shift_only(event) && event.which === 9);
export const is_enter = event => !is_composited_key(event) && (event.which === 13);
export const is_esc = event => !is_composited_key(event) && (event.which === 27);
export const clickEvent = new MouseEvent("click", { bubbles: true, cancelable: true, });

export function stopPropagationEventsOnDOMElement(element, types=[]) {
   if(element instanceof HTMLElement) {
      types.forEach(type => element.addEventListener(type, (event) => { event.preventDefault(); event.stopPropagation();}));
   }
}

export function prevent_event_propagation(e) {
   // e.preventDefault();
   e.stopPropagation();
}

