import { WorklistRow } from "./worklist";

interface RelatedWorklistState {
   row?: WorklistRow;
   activeTabCode: number;
   redrawId?: string;
}
const INITIAL_RELATED_WORKLIST_STATE: RelatedWorklistState = {
   row: undefined,
   activeTabCode: 0,
   redrawId: undefined,
};

export enum RelatedWorklistActionType {
   SELECT_RELATED_ROW = "selectRelatedWorklistRow",
   CLEAR_RELATED_ROW = "clearRelatedWorklistRow",
   SET_ACTIVE_TAB = "setActiveTab",
   REDRAW_RELATED_EXAM = "redrawRelatedExam"
}
export interface SelectRelatedRowAction {
   type: RelatedWorklistActionType.SELECT_RELATED_ROW;
   payload: WorklistRow;
}
export interface ClearRelatedRowAction {
   type: RelatedWorklistActionType.CLEAR_RELATED_ROW;
}
export interface SetActiveTabAction {
   type: RelatedWorklistActionType.SET_ACTIVE_TAB;
   payload: number;
}
export interface RedrawRelatedExamAction {
   type: RelatedWorklistActionType.REDRAW_RELATED_EXAM;
   payload: string;
}
export type RelatedWorklistAction =
| SelectRelatedRowAction
| ClearRelatedRowAction
| SetActiveTabAction
| RedrawRelatedExamAction;

export function relatedWorklistReducer(state = INITIAL_RELATED_WORKLIST_STATE, action: RelatedWorklistAction): RelatedWorklistState {
   switch (action.type) {
   case RelatedWorklistActionType.SELECT_RELATED_ROW: {
      return { ...state, row: action.payload };
   }
   case RelatedWorklistActionType.CLEAR_RELATED_ROW: {
      return { ...state, row: undefined };
   }
   case RelatedWorklistActionType.SET_ACTIVE_TAB: {
      return { ...state, activeTabCode: action.payload };
   }
   case RelatedWorklistActionType.REDRAW_RELATED_EXAM: {
      return { ...state, redrawId: action.payload };
   }
   default:
      return state;
   }
}
