import { TechlistRow } from "./techlist";

interface ReportState {
   reportRow?: TechlistRow;
}
const INITIAL_REPORT_STATE: ReportState = {
   reportRow: undefined,
};

export enum TechlistReportActionType {
   SELECT_REPORT_ROW = "selectTechlistReportRow",
   CLEAR_REPORT_ROW = "clearTechlistReportRow",
}
interface SelectThumbnailRowAction {
   type: TechlistReportActionType.SELECT_REPORT_ROW;
   payload: TechlistRow;
}
interface ClearThumbnailRowAction {
   type: TechlistReportActionType.CLEAR_REPORT_ROW;
}
type ReportAction =
| SelectThumbnailRowAction
| ClearThumbnailRowAction;

export function techlistReportReducer(state = INITIAL_REPORT_STATE, action: ReportAction): ReportState {
   switch (action.type) {
   case TechlistReportActionType.SELECT_REPORT_ROW: {
      return { ...state, reportRow: action.payload };
   }
   case TechlistReportActionType.CLEAR_REPORT_ROW: {
      return { ...state, reportRow: undefined };
   }
   default:
      return state;
   }
}
