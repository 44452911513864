import moment from "moment-timezone";
import i18next from "i18next";
import GridUtils from "./grid-utils";

export default class RadiologyUtils {

   static convertParmas(obj){
      let newObj = {};
      newObj = JSON.parse(JSON.stringify(obj));
      if(newObj.requestDtime) {

         // newObj.requestDtime.dateFrom = this.convertDateToTimestamp(newObj.requestDtime.dateFrom);
         newObj.requestDtime.dateFrom = moment(newObj.requestDtime.dateFrom).unix() * 1000;
         if(newObj.requestDtime.dateTo === "null null") {
            newObj.requestDtime.dateTo = null;
         } else {
            // newObj.requestDtime.dateTo = this.convertDateToTimestamp(newObj.requestDtime.dateTo);
            newObj.requestDtime.dateTo = moment(newObj.requestDtime.dateTo).unix() * 1000;
         }
      }
      if(newObj.studyDtime) {
         newObj.studyDtime.dateFrom = moment(newObj.studyDtime.dateFrom).format("YYYY-MM-DD");
         if(newObj.studyDtime.dateTo === "null null") {
            newObj.studyDtime.dateTo = null;
         } else {
            newObj.studyDtime.dateTo = moment(newObj.studyDtime.dateTo).format("YYYY-MM-DD");
         }
      }

      if (newObj.isEmergency) {
         const sortedArray = newObj.isEmergency.values.sort();
         if(GridUtils.compareArray(sortedArray, ["N"])) {
            newObj.isEmergency.values = ["false"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
         }
         else if(GridUtils.compareArray(sortedArray, ["E"])) {
            newObj.isEmergency.values = ["true"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
         }
         else if(GridUtils.compareArray(sortedArray, ["C"])) {
            newObj.isEmergency = null;
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
         else if(GridUtils.compareArray(sortedArray, ["N", "E"])) {
            newObj.isEmergency = null;
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
         }
         else if(GridUtils.compareArray(sortedArray, ["E", "C"])) {
            newObj.isEmergency.values = ["true"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
         else if(GridUtils.compareArray(sortedArray, ["N", "C"])) {
            newObj.isEmergency.values = ["false"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
         else if(GridUtils.compareArray(sortedArray, ["N", "E", "C"])) {
            newObj.isEmergency = null;
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
      }

      return newObj;
   }

   static makeDeleteReadingTemplateMessage(params) {
      const {hasTemplate} = params;
      if(hasTemplate) return i18next.t("msg.deleteReadingTemplate.hasTemplate", { returnObjects: true });
      return [i18next.t("msg.deleteReadingTemplate.default")];
   }
}
