interface Filmbox {
   caseId?: string;
   seriesId?: string;
   imageIndex?: number;
   related?: object;
}

interface Tab {
   related: [];
}

interface FilmboxState {
   filmbox?: Filmbox;
   tab?: Tab,
}

const INITIAL_FILMBOX_STATE: FilmboxState = {
   filmbox: undefined,
   tab: undefined,
};

export enum FilmboxActionType {
   OPEN_FILMBOX = "openFilmbox",
   CLOSE_FILMBOX = "closeFilmbox",
   CHANGE_TAB = "changeTab",
}

export interface OpenFilmbox {
   type: FilmboxActionType.OPEN_FILMBOX;
   payload: Filmbox;
}

export interface CloseFilmbox {
   type: FilmboxActionType.CLOSE_FILMBOX;
}

export interface ChangeTab {
   type: FilmboxActionType.CHANGE_TAB;
   payload: Tab
}

export type FilmboxAction =
   | OpenFilmbox
   | CloseFilmbox
   | ChangeTab;

export function filmboxReducer(state = INITIAL_FILMBOX_STATE, action: FilmboxAction): FilmboxState {
   switch (action.type) {
   case FilmboxActionType.OPEN_FILMBOX: {
      return { ...state, filmbox: action.payload };
   }
   case FilmboxActionType.CLOSE_FILMBOX: {
      return { ...state, filmbox: undefined };
   }
   case FilmboxActionType.CHANGE_TAB: {
      return { ...state, tab: action.payload };
   }
   default:
      return state;
   }
}
