import { Filter, AppliedFilter } from "./worklist";

export interface TechlistRow {
   detail: {
      id: string;
   },
   rows: []
}
interface TechlistState {
   row?: TechlistRow;
   redrawSelectedRows: boolean;
   refreshAndSelectRows?: object[];
   filter?: Filter; // 적용할 필터
   appliedFilter?: AppliedFilter; // 적용된 필터
   appliedSortModel?: object;
}
const INITIAL_TECHLIST_STATE: TechlistState = {
   row: undefined,
   redrawSelectedRows: false,
   refreshAndSelectRows: undefined,
   filter: undefined,
   appliedFilter: undefined,
   appliedSortModel: undefined,
};

export enum TechlistActionType {
   SELECT_ROW = "selectTechlistRow",
   CLEAR_ROW_SELECTION = "clearTechlistSelection",
   REDRAW_SELECTED_ROWS = "updateSelectedTechlistRows",
   REFRESH_AND_SELECT_ROWS = "refreshAndSelectRows",
   SET_FILTER = "setTechlistFilter",
   SET_APPLIED_FILTER = "setAppliedTechlistFilter",
   SET_APPLIED_SORT_MODEL = "setAppliedSortModel",
}
export interface SelectRowAction {
   type: TechlistActionType.SELECT_ROW;
   payload: TechlistRow;
}
export interface ClearRowAction {
   type: TechlistActionType.CLEAR_ROW_SELECTION;
}
export interface RedrawSelectedRowAction {
   type: TechlistActionType.REDRAW_SELECTED_ROWS;
   payload: boolean;
}
export interface RefreshAndSelectRows {
   type: TechlistActionType.REFRESH_AND_SELECT_ROWS;
   payload: object[];
}
export interface SetFilter {
   type: TechlistActionType.SET_FILTER;
   payload: Filter;
}
export interface SetAppliedFilter {
   type: TechlistActionType.SET_APPLIED_FILTER;
   payload: AppliedFilter;
}
export interface SetAppliedSortModel {
   type: TechlistActionType.SET_APPLIED_SORT_MODEL;
   payload: object;
}
export type TechlistAction =
| SelectRowAction
| ClearRowAction
| RedrawSelectedRowAction
| RefreshAndSelectRows
| SetFilter
| SetAppliedFilter
| SetAppliedSortModel;

export function techlistReducer(state = INITIAL_TECHLIST_STATE, action: TechlistAction): TechlistState {
   switch (action.type) {
   case TechlistActionType.SELECT_ROW: {
      return { ...state, row: action.payload };
   }
   case TechlistActionType.CLEAR_ROW_SELECTION: {
      return { ...state, row: undefined };
   }
   case TechlistActionType.REDRAW_SELECTED_ROWS: {
      return { ...state, redrawSelectedRows: action.payload };
   }
   case TechlistActionType.REFRESH_AND_SELECT_ROWS: {
      return { ...state, refreshAndSelectRows: action.payload };
   }
   case TechlistActionType.SET_FILTER: {
      return { ...state, filter: action.payload };
   }
   case TechlistActionType.SET_APPLIED_FILTER: {
      return { ...state, appliedFilter: action.payload };
   }
   case TechlistActionType.SET_APPLIED_SORT_MODEL: {
      return { ...state, appliedSortModel: action.payload };
   }
   default:
      return state;
   }
}
