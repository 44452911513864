import i18next from "i18next";
import moment from "moment-timezone";
import CommonUtils from "../../public/resource/js/utils/common";
import GridUtils from "./grid-utils";

export default class TechnicianUtils {

   // constructor() {}

   /**
    * UnVerify
    * @param rows {array}
    * @returns {Promise<unknown>}
    */
   static unVerify(rows) {
      const rowIds = (rows||[]).map(row => row.id);
      return new Promise((resolve, reject) => {
         fetch(`/api/verify/case/${rowIds}`, {
            method: "DELETE",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if (response.ok) {
               resolve(true);
            } else {
               console.log("TechTab un verify studylist failed!");
               resolve(false);
            }
         }).catch((err) => {
            reject(err);
         });
      });
   }

   /**
    * Match
    * @param studyRows {array}
    * @param orderRow {object}
    * @returns {Promise<unknown>}
    */
   static match( studyRows = [], orderRow ) {
      const orderId = orderRow?.id;
      if (!orderId) return;

      const studyIds = studyRows.map(row => row.id);
      return new Promise((resolve) => {
         fetch(`/api/tech/orderlist/match/${orderId}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(studyIds)
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve(true);
            } else {
               resolve(false);
            }
         });
      });
   }

   /**
    * UnMatch
    * @param studyRows
    * @param orderRow
    * @returns {Promise<unknown>}
    */
   static unMatch(studyRows = [], orderRow) {
      const param = {};
      if (orderRow) {
         Object.assign(param, {"order": orderRow.id});
      }
      if (studyRows.length > 0) {
         studyRows.forEach((study, i) => {
            const sKey = `study${i}`;
            Object.assign(param, {[sKey]: study.id});
         });
      }

      return new Promise((resolve) => {
         fetch(`/api/tech/orderlist/unmatch`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve(true);
            } else {
               resolve(false);
            }
         });
      });
   }

   static makeMatchUnMatchMessage({ matchMsgCode }) {
      if (CommonUtils.isEmptyValue(matchMsgCode)) return [];

      const msg = [];
      const msgCode = matchMsgCode.split(",");
      // Verified 포함
      if (msgCode.length === 2 && msgCode[0] === "W") {
         // Verified 된 검사가 포함되어 있습니다.
         msg.push(i18next.t("msg.verify.include"));
      }

      const code = msgCode[1];
      if (code === "M") {
         // Match 하시겠습니까?
         msg.push(i18next.t("msg.match.m"));
      } else if (code === "U") {
         // UnMatch 하시겠습니까?
         msg.push(i18next.t("msg.match.u"));
      } else if (code === "MS") {
         // 기존에 Match 된 케이스가 있습니다.
         msg.push(i18next.t("msg.match.ms.0"));
         // 기존 Match 된 케이스를 풀고 선택한 케이스를 Match 하시겠습니까?
         msg.push(i18next.t("msg.match.ms.1"));
      } else if (code === "MD") {
         // 기존에 이미 매칭이 존재합니다.
         msg.push(i18next.t("msg.match.md.0"));
         // 그래도 매칭을 진행하시겠습니까?
         msg.push(i18next.t("msg.match.md.1"));
      } else {
         msg.push(i18next.t("msg.match.err"));
      }

      return msg;
   }

   static caseLockUpdate(detail) {
      return new Promise((resolve) => {
         fetch(`/api/tech/studylist/caseLock/${detail.flag}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            keepalive: true,
            body: JSON.stringify(detail.list)
         }).then((response) => {
            if (!response.ok) {
               resolve(false);
               // document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: i18next.t("msg.caseLockFail"), isErr: true} }));
            } else {
               resolve(true);
               // redraw selected technician rows
               // store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
            }
         });
      });
   }

   static deleteStudyByIds(studyRows = []) {
      const ids = studyRows.map(row => row.id);
      if (ids.length === 0) return;
      return new Promise((resolve, reject) => {
         fetch(`/api/exchange/studylist/delete/${ids}`, {
            method: "DELETE",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if (response.ok) {
               resolve(true);
            } else {
               console.warn("delete Study is Failed");
               resolve(false);
            }
         }).catch((err) => {
            reject(err);
         });
      });
   }

   static conversionFilter(filterModel = {}) {
      let newObj = {};
      newObj = JSON.parse(JSON.stringify(filterModel));

      if (newObj.requestDtime) {
         newObj.requestDtime.dateFrom = moment(newObj.requestDtime.dateFrom).unix() * 1000;
         if(newObj.requestDtime.dateTo === "null null") {
            newObj.requestDtime.dateTo = null;
         } else {
            newObj.requestDtime.dateTo = moment(newObj.requestDtime.dateTo).unix() * 1000;
         }
      }

      if (newObj.studyDtime) {
         newObj.studyDtime.dateFrom = moment(newObj.studyDtime.dateFrom).format("YYYY-MM-DD");
         if (newObj.studyDtime.dateTo === "null null") {
            newObj.studyDtime.dateTo = null;
         } else {
            newObj.studyDtime.dateTo = moment(newObj.studyDtime.dateTo).format("YYYY-MM-DD");
         }
      }

      if (newObj.isEmergency) {
         const sortedArray = newObj.isEmergency.values.sort();
         if(GridUtils.compareArray(sortedArray, ["N"])) {
            newObj.isEmergency.values = ["false"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
         }
         else if(GridUtils.compareArray(sortedArray, ["E"])) {
            newObj.isEmergency.values = ["true"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
         }
         else if(GridUtils.compareArray(sortedArray, ["C"])) {
            newObj.isEmergency = null;
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
         else if(GridUtils.compareArray(sortedArray, ["N", "E"])) {
            newObj.isEmergency = null;
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
         }
         else if(GridUtils.compareArray(sortedArray, ["E", "C"])) {
            newObj.isEmergency.values = ["true"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
         else if(GridUtils.compareArray(sortedArray, ["N", "C"])) {
            newObj.isEmergency.values = ["false"];
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
         else if(GridUtils.compareArray(sortedArray, ["N", "E", "C"])) {
            newObj.isEmergency = null;
            newObj.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
         }
      }

      if (newObj.readingStatus) {
         const values = [];
         newObj.readingStatus.values.forEach((value) => {
            if (value === "A") {
               values.push("3A");
            } else if (value === "W") {
               values.push("1W");
            } else if (value === "H/O") {
               values.push("2T");
            } else if (value === "T") {
               values.push("2DT");
            }
         });
         newObj.readingStatus.values = values;
      }

      if (newObj.isMatch) {
         if (newObj.isMatch.values.find(value => value === "(M) Matched")) newObj.isMatch.values[0] = Boolean(true);
         if (newObj.isMatch.values.find(value => value === "(NM) Not Matched")) newObj.isMatch.values[0] = Boolean(false);
      }

      if (newObj.isMerge) {
         if (newObj.isMerge.values.find(value => value === "(M) Merged")) newObj.isMerge.values[0] = Boolean(true);
         if (newObj.isMerge.values.find(value => value === "(NM) Not Merged")) newObj.isMerge.values[0] = Boolean(false);
      }

      return newObj;
   }

}

// const technicianUtils = new TechnicianUtils();
// Object.freeze(technicianUtils);

// export default technicianUtils;
