export enum QueryKey {
   GET_RELATED_WORKLIST = "getRelatedWorklist",
   GET_THUMBNAIL_IMAGE = "getThumbnailImage",
   GET_IMAGE_REPORT = "getImageReport",
   GET_MODALITY_LIST = "getModalityList",
   GET_BODYPART_LIST = "getBodypartList",
   GET_READING_TEMPLATE = "getReadingTemplate",
   GET_CUSTOM_FILTER_LIST = "getCustomFilterList",
   GET_SHORTCUT_LIST = "getShortcutList",
   GET_COUNT = "getCount",
}

interface GetRelatedWorklistResult {
   id: string;
   result?: any;
}
export interface GetRelatedWorklistAction {
   type: QueryKey.GET_RELATED_WORKLIST;
   payload: GetRelatedWorklistResult;
}

interface GetThumbnailImageResult {
   id: string;
   image?: any;
}
export interface GetThumbnailImageAction {
   type: QueryKey.GET_THUMBNAIL_IMAGE;
   payload: GetThumbnailImageResult;
}

interface GetImageReportResult {
   image?: any;
}
export interface GetImageReportAction {
   type: QueryKey.GET_IMAGE_REPORT;
   payload: GetImageReportResult;
}

interface GetModalitylistResult {
   result?: any;
}
export interface GetModalitylistAction {
   type: QueryKey.GET_MODALITY_LIST;
   payload: GetModalitylistResult;
}

interface GetBodypartlistResult {
   result?: any;
}
export interface GetBodypartlistAction {
   type: QueryKey.GET_BODYPART_LIST;
   payload: GetBodypartlistResult;
}

interface GetReadingTemplateResult {
   result?: any;
}
export interface GetReadingTemplateAction {
   type: QueryKey.GET_READING_TEMPLATE;
   payload: GetReadingTemplateResult;
}

export interface CustomFilter {
   result?: any;
}
export interface GetCustomFilterListAction {
   type: QueryKey.GET_CUSTOM_FILTER_LIST;
   payload: CustomFilter;
}

interface GetShortcutResult {
   filmboxFunction?: object[];
   macroFunction?: object[];
   worklistFunction?: object[];
}
export interface GetShortcutAction {
   type: QueryKey.GET_SHORTCUT_LIST;
   payload: object[];
}

interface GetCountResult {
   result?: any;
}
export interface GetCountAction {
   type: QueryKey.GET_COUNT;
   payload: GetCountResult;
}

export type QueryAction =
| GetRelatedWorklistAction
| GetThumbnailImageAction
| GetImageReportAction
| GetModalitylistAction
| GetBodypartlistAction
| GetReadingTemplateAction
| GetCustomFilterListAction
| GetShortcutAction
| GetCountAction;

export type QueryState = {
   [QueryKey.GET_RELATED_WORKLIST]?: GetRelatedWorklistResult;
   [QueryKey.GET_THUMBNAIL_IMAGE]?: GetThumbnailImageResult;
   [QueryKey.GET_IMAGE_REPORT]?: GetImageReportResult;
   [QueryKey.GET_MODALITY_LIST]?: GetModalitylistResult;
   [QueryKey.GET_BODYPART_LIST]?: GetBodypartlistResult;
   [QueryKey.GET_READING_TEMPLATE]?: GetReadingTemplateResult;
   [QueryKey.GET_CUSTOM_FILTER_LIST]?: CustomFilter;
   [QueryKey.GET_SHORTCUT_LIST]?: GetShortcutResult
   [QueryKey.GET_COUNT]?: GetCountResult;
};

const INTIAL_QUERY_STATE: QueryState = {};

export function queryReducer(state = INTIAL_QUERY_STATE, action: QueryAction): QueryState {
   if (Object.values(QueryKey).includes(action.type)) {
      return { ...state, [action.type]: action.payload };
   }
   return state;
}
