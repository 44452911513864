import { WorklistRow, RowDetail } from "./worklist";

export interface ReportRow extends WorklistRow {
   // detail?: RowDetail,
   // rows?: RowDetail[],
   oldRow?: RowDetail,
   oldRows?: RowDetail[]
}

export interface CopyReport {
   detail: {
      finding: string,
      conclusion: string,
      recommendation: string,
      addendum?: string,
      report?: string,
      id?: string,
      title?: string,
   },
   paste?: boolean
}

interface ReportState {
   thumbnailRow?: string;
   clinicalInfoRow?: string;
   reportRow?: ReportRow;
   readingTemplateSensitive?: object;
   readingTemplateOpinion?: object;
   copyAndPasteReport?: CopyReport;
   userDn?: string;
   mismatchContinue: boolean;
   isEditing: boolean;
}
const INITIAL_REPORT_STATE: ReportState = {
   thumbnailRow: undefined,
   clinicalInfoRow: undefined,
   reportRow: undefined,
   readingTemplateSensitive: undefined,
   readingTemplateOpinion: undefined,
   copyAndPasteReport: undefined,
   userDn: undefined,
   mismatchContinue: false,
   isEditing: false,
};

export enum ReportActionType {
   SELECT_THUMBNAIL_ROW = "selectThumbnailRow",
   CLEAR_THUMBNAIL_ROW = "clearThumbnailRow",
   SELECT_CLINICALINFO_ROW = "selectClinicalInfoRow",
   SELECT_REPORT_ROW = "selectReportRow",
   SET_READING_TEMPLATE_SENSITIVE = "setReadingTemplateSensitive",
   SET_READING_TEMPLATE_OPINION = "setReadingTemplateOpinion",
   SET_COPY_AND_PASTE_REPORT = "setCopyAndPasteReport",
   SET_COPY_REPORT = "setCopyReport",
   CLEAR_COPY_AND_PASTE_REPORT = "clearCopyAndPasteReport",
   SET_USERDN = "setUserDn",
   REMOVE_USERDN = "removeUserDn",
   MISMATCH_CONTINUE = "mismatchContinue",
   CLEAR_MISMATCH_CONTINUE = "clearMismatchContinue",
   SET_IS_EDITING = "setIsEditing",
}
export interface SelectThumbnailRowAction {
   type: ReportActionType.SELECT_THUMBNAIL_ROW;
   payload: string;
}
export interface ClearThumbnailRowAction {
   type: ReportActionType.CLEAR_THUMBNAIL_ROW;
}
export interface SelectClinicalInfoRowAction {
   type: ReportActionType.SELECT_CLINICALINFO_ROW;
   payload: string;
}
export interface SelectReportRowAction {
   type: ReportActionType.SELECT_REPORT_ROW;
   payload: ReportRow;
}
interface SetReadingTemplateSensitiveAction {
   type: ReportActionType.SET_READING_TEMPLATE_SENSITIVE;
   payload: object;
}
interface SetReadingTemplateOpinionAction {
   type: ReportActionType.SET_READING_TEMPLATE_OPINION;
   payload: object;
}
interface SetCopyAndPasteReportAction {
   type: ReportActionType.SET_COPY_AND_PASTE_REPORT;
   payload: CopyReport;
}
interface SetCopyReportAction {
   type: ReportActionType.SET_COPY_REPORT,
   payload: CopyReport;
}
interface ClearCopyAndPasteReportAction {
   type: ReportActionType.CLEAR_COPY_AND_PASTE_REPORT;
}
interface SetUserDnAction {
   type: ReportActionType.SET_USERDN;
   payload: string;
}
interface RemoveUserDnAction {
   type: ReportActionType.REMOVE_USERDN;
}
interface MismatchContinueAction {
   type: ReportActionType.MISMATCH_CONTINUE;
}
interface ClearMismatchContinueAction {
   type: ReportActionType.CLEAR_MISMATCH_CONTINUE;
}
interface SetIsEditingAction {
   type: ReportActionType.SET_IS_EDITING;
   payload: boolean;
}
export type ReportAction =
| SelectThumbnailRowAction
| ClearThumbnailRowAction
| SelectClinicalInfoRowAction
| SelectReportRowAction
| SetReadingTemplateSensitiveAction
| SetReadingTemplateOpinionAction
| SetCopyAndPasteReportAction
| SetCopyReportAction
| ClearCopyAndPasteReportAction
| SetUserDnAction
| RemoveUserDnAction
| MismatchContinueAction
| ClearMismatchContinueAction
| SetIsEditingAction;

export function reportReducer(state = INITIAL_REPORT_STATE, action: ReportAction): ReportState {
   switch (action.type) {
   case ReportActionType.SELECT_THUMBNAIL_ROW: {
      return { ...state, thumbnailRow: action.payload };
   }
   case ReportActionType.CLEAR_THUMBNAIL_ROW: {
      return { ...state, thumbnailRow: undefined };
   }
   case ReportActionType.SELECT_CLINICALINFO_ROW: {
      return { ...state, clinicalInfoRow: action.payload };
   }
   case ReportActionType.SELECT_REPORT_ROW: {
      return { ...state, reportRow: action.payload };
   }
   case ReportActionType.SET_READING_TEMPLATE_SENSITIVE: {
      return { ...state, readingTemplateSensitive: action.payload };
   }
   case ReportActionType.SET_READING_TEMPLATE_OPINION: {
      return { ...state, readingTemplateOpinion: action.payload };
   }
   case ReportActionType.SET_COPY_AND_PASTE_REPORT: {
      return { ...state, copyAndPasteReport: { ...action.payload, paste: true } };
   }
   case ReportActionType.SET_COPY_REPORT: {
      return { ...state, copyAndPasteReport: action.payload };
   }
   case ReportActionType.CLEAR_COPY_AND_PASTE_REPORT: {
      return { ...state, copyAndPasteReport: undefined };
   }
   case ReportActionType.SET_USERDN: {
      return { ...state, userDn: action.payload };
   }
   case ReportActionType.REMOVE_USERDN: {
      return { ...state, userDn: undefined };
   }
   case ReportActionType.MISMATCH_CONTINUE: {
      return { ...state, mismatchContinue: true };
   }
   case ReportActionType.CLEAR_MISMATCH_CONTINUE: {
      return { ...state, mismatchContinue: false };
   }
   case ReportActionType.SET_IS_EDITING: {
      return { ...state, isEditing: action.payload };
   }
   default:
      return state;
   }
}
