import { TechlistRow } from "./techlist";

interface TechnicianOrder {
   row: {
      id: string;
   },
   rows: []
}

interface RelatedTechlistState {
   row?: TechlistRow;
   activeTabCode: number;
   order?: TechnicianOrder;
   redrawOrderIds?: string[];
   refreshOrder: boolean;
}
const INITIAL_RELATED_TECHLIST_STATE: RelatedTechlistState = {
   row: undefined,
   activeTabCode: 0,
   order: undefined,
   redrawOrderIds: undefined,
   refreshOrder: false,
};

export enum RelatedTechlistActionType {
   SELECT_RELATED_ROW = "selectRelatedTechlistRow",
   CLEAR_RELATED_ROW = "clearRelatedTechlistRow",
   SET_ACTIVE_TAB = "setRelatedTechlistActiveTab",
   SELECT_ORDER = "selectTechlistOrder",
   CLEAR_ORDER = "clearTechlistOrder",
   REDRAW_ORDER_ROWS = "redrawOrderRows",
   REFRESH_ORDER = "refreshOrder"
}
export interface SelectRelatedRowAction {
   type: RelatedTechlistActionType.SELECT_RELATED_ROW;
   payload: TechlistRow;
}
export interface ClearRelatedRowAction {
   type: RelatedTechlistActionType.CLEAR_RELATED_ROW;
}
export interface SetActiveTabAction {
   type: RelatedTechlistActionType.SET_ACTIVE_TAB;
   payload: number;
}
export interface SelectOrderAction {
   type: RelatedTechlistActionType.SELECT_ORDER;
   payload: TechnicianOrder;
}
export interface ClearOrderAction {
   type: RelatedTechlistActionType.CLEAR_ORDER;
}
export interface RedrawOrderRowsAction {
   type: RelatedTechlistActionType.REDRAW_ORDER_ROWS;
   payload: string[];
}
export interface RefreshOrderAction {
  type: RelatedTechlistActionType.REFRESH_ORDER;
  payload: boolean;
}
export type RelatedTechlistAction =
| SelectRelatedRowAction
| ClearRelatedRowAction
| SetActiveTabAction
| SelectOrderAction
| ClearOrderAction
| RedrawOrderRowsAction
| RefreshOrderAction;

export function relatedTechlistReducer(state = INITIAL_RELATED_TECHLIST_STATE, action: RelatedTechlistAction): RelatedTechlistState {
   switch (action.type) {
   case RelatedTechlistActionType.SELECT_RELATED_ROW: {
      return { ...state, row: action.payload };
   }
   case RelatedTechlistActionType.CLEAR_RELATED_ROW: {
      return { ...state, row: undefined };
   }
   case RelatedTechlistActionType.SET_ACTIVE_TAB: {
      return { ...state, activeTabCode: action.payload };
   }
   case RelatedTechlistActionType.SELECT_ORDER: {
      return { ...state, order: action.payload };
   }
   case RelatedTechlistActionType.CLEAR_ORDER: {
      return { ...state, order: undefined };
   }
   case RelatedTechlistActionType.REDRAW_ORDER_ROWS: {
      return { ...state, redrawOrderIds: action.payload };
   }
   case RelatedTechlistActionType.REFRESH_ORDER: {
      return { ...state, refreshOrder: action.payload };
   }
   default:
      return state;
   }
}
