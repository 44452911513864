import { createStore, applyMiddleware } from "redux";
import { createStateSyncMiddleware, initStateWithPrevTab } from "redux-state-sync";

import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import reducers from "./reducers/index";

// @ts-ignore
if (!window.reduxChannel) {
   // @ts-ignore
   window.reduxChannel = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line no-bitwise
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

const config = {
   blacklist: ["openDialog", "closeDialog"],
   // @ts-ignore
   channel: window.reduxChannel,
};

// https://redux-toolkit.js.org/api/configureStore
let middlewares;
let configStore;
if (process.env.NODE_ENV === "prod") {
   middlewares = [createStateSyncMiddleware(config)];
   configStore = { reducer: reducers, middleware: middlewares, devTools: false };
} else {
   if (import.meta.env.MODE === "debug") {
      middlewares = [createStateSyncMiddleware(config), logger];
   } else {
      middlewares = [createStateSyncMiddleware(config)];
   }
   configStore = { reducer: reducers, middleware: middlewares, devTools: true };
}
const store = configureStore(configStore);

initStateWithPrevTab(store);

// store.subscribe(() => console.log(store.getState()));

export default store;
