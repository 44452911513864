type filters = {
   isEmergency: any,
   readingStatus: any,
   studyStatus: any,
   teleStatus: any,
   patientSex: any,
   modality: any,
   bodypart: any,
   requestHospital: any,
   checkupHospital: any,
   checkupDepartment: any,
   serviceName: any
}

interface GridState {
   filters: filters | undefined
}
const INITIAL_COMMON_STATE: GridState = {
   filters: undefined,
};
export enum GridActionType {
   SET_FILTERS = "setFilters",
}

interface SetGridFilterAction {
   type: GridActionType.SET_FILTERS;
   payload: filters;
}

type GridAction =
   | SetGridFilterAction;

export function gridReducer(state = INITIAL_COMMON_STATE, action: GridAction): GridState {
   switch (action.type) {
   case GridActionType.SET_FILTERS: {
      return { ...state, filters: action.payload };
   }
   default: {
      return state;
   }
   }
}
